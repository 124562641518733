import styled from 'styled-components';

export const View = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-left: 12px;
  padding-right: 12px;

  .bloco {
    background: #fff;
    border-radius: 6px;
    padding: 8px;
    margin-top: 12px;
  }

  p {
    margin-top: 12px;
    font-size: 12px;
  }

  @media only screen and (min-width: 481px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (min-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Banner = styled.div`
  display: none;
  width: 100%;
  height: auto;
  background: #eee;

  @media only screen and (min-width: 481px) {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    display: block;
  }

  @media only screen and (min-width: 1280px) {
    display: block;
  }

  img {
    width: 100%;
  }
`;

export const Anuncio = styled.div`
  display: block;
  width: 100%;
  margin-top: 12px;

  img {
    width: 100%;
  }
`;

export const Parceiro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  padding: 6px 12px;

  div {
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      flex-direction: row;

      img {
        width: 100px;
      }
    }
  }
`;
